import './index.css'

const LoginPage = () => {
    return (
        <div className="p-Login">
            Login
        </div>
    )
}

export { LoginPage }