import { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { NavigatorBannerComponent } from './components/NavigatorBanner'
import { NavigationBarComponent } from './components/NavigationBarComponent'
import { ScrollToTop } from './components/ScrollToTop'
import { SpinnerComponent } from './components/Spinner'

import { LoginPage } from './pages/Login'
import { MainPage } from './pages/Main'

import { useSimpleState } from './hooks/useSimpleState'
import { useView } from './hooks/useView'

import { AppProvider } from './hooks/useAppContext/context'

import './styles/main.css'


function App() {
    const { _state } = useSimpleState({})

    const {_view} = useView({
        initialView: 'main',
        views: {
            loading: renderLoading,
            main: renderMain
        }
    })

    function renderLoading() {
        return (
            <div className="app-container">
                <SpinnerComponent />
            </div>
        )
    }

    function renderMain() {
        return (
            <div className="app-container">
                <AppProvider value={{ _state }}>
                    <BrowserRouter>
                        <ScrollToTop>
                            <NavigationBarComponent />
                            {/* <NavigatorBannerComponent /> */}
                            <Routes>
                                <Route path="/login" element={<LoginPage />} exact />
                                <Route path="/" element={<MainPage />} />
                            </Routes>
                        </ScrollToTop>
                    </BrowserRouter>
                </AppProvider>
            </div>
        )
    }

    return _view.get()
}

export default App;
