import { useState } from 'react'

import { useBrowserType } from '../../hooks/useBrowserType'

import './index.css'

const NavigatorBannerComponent = () => {
    const [show, setShow] = useState(!Boolean(localStorage.getItem('hasDismissedBanner')))

    if (!show)
        return

    const handleClick = () => {
        localStorage.setItem('hasDismissedBanner', true)
        setShow(false)
    }

    return show ? (
        <div className="c-NavigatorBanner-container">
            <div className="c-NavigatorBanner animation-fadeIn">
                <div className="navigationBanner-controls">
                    <button className="button" onClick={handleClick}><i className="bi bi-x"></i></button>
                </div>

                <div className="navigationBanner-logo"></div>

                <div className="navigationBanner-copy">
                    <div className="navigationBanner-copyTitle">Viewing FYCDeals.com <span className="no-wrap">in a social app?</span></div>

                    <div className="disclaimer">Click top right to <span className="navigationBanner-copyCta">open www.fycdeals.com in your standard mobile browser</span> so your deals open in <strong className="no-wrap">mobile apps</strong></div>
                </div>
            </div>
        </div>
    ) : null
}

export { NavigatorBannerComponent }