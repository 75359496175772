import './index.css'

const NavigationBarComponent = () => {
    return (
        <div className="c-NavigationBar">
            <h1><span>FYC Deals</span></h1>
        </div>
    )
}

export { NavigationBarComponent }