import { useEffect, useRef } from 'react'

import { useNavigate } from 'react-router-dom'

import { DealComponent } from '../Deal'
import { FilterSliderComponent } from '../FilterSlider'
import { LayoutPage } from '../LayoutPage'
import { SpinnerComponent } from '../Spinner'

import { useControlSearch } from '../../hooks/useControlSearch'
import { useSimpleState } from '../../hooks/useSimpleState'
import { useView } from '../../hooks/useView'

import { FetchHelper } from '../../helpers/fetch'

import dayjs from 'dayjs'

import './index.css'

const DealsComponent = ({ value, config={}, events={} }) => {
    const navigate = useNavigate();

    const {_state} = useSimpleState({
        fetching: false,
        filterPriceRange: [10, 30]
    })

    const {_view} = useView({
        initialView: 'loading',
        views: {
            loading: renderLoading,
            main: renderMain
        }
    })

    const {_control, _results, _params} = useControlSearch({
        beforeFetch: () => {
            // _view.set('loading')
        },
        afterFetch: () => {
            _view.set('main')
        }
    })

    const handleFilterPriceChange = (value) => {
        _params.set('priceFinal', value)
    }

    const handleFilterPriceReset = () => {
        _params.set('priceFinal', null)
    }

    const handleDealClick = (item) => {
        _control.fetchClick(item)

        setTimeout(() => {
            window.open(item.url, '_blank')
        }, 500)
    }

    const handleDealClickDead = (item) => {
        _control.fetchClickDead(item)
    }

    const getList = () => {
        const deals = _results.get()?.body?.data

        if (!deals?.length) {
            return (
                <div className="deals-dealsSectionNoResults">
                    <h2>No Deals...</h2>
                    <p>Start saving for when more show up!</p>
                </div>
            )
        }

        return (
            deals.map((item, index) => {
                return (
                    <div className="deals-dealsSection" key={`deals-dealsSection-${item.date}-${index}`}>
                        <div className="deals-dealsSectionDate">
                            <span className="bubble bubble-green">{dayjs(item.date).format('MMMM D, YYYY')}</span>
                        </div>

                        <div className="deals-dealsSectionItems">
                            {item.items.map((itm, index) => {
                                return <DealComponent value={itm} key={`${JSON.stringify(itm)}-${index}`} events={{
                                    handleClick: handleDealClick,
                                    handleClickDead: handleDealClickDead
                                }} />
                            })}
                        </div>
                    </div>
                )
            })
        )
    }

    function renderLoading() {
        return (
            <div className="deals-deals">
                <SpinnerComponent />
            </div>
        )
    }

    function renderMain() {
        return (
            <div className="deals-deals">
                {/* <div className="deals-filters">
                    <FilterSliderComponent value={_state.get('filterPriceRange')} events={{
                        handleChange: handleFilterPriceChange,
                        handleClickReset: handleFilterPriceReset
                    }} />
                </div> */}
                <div className="deals-dealsCollection">
                    {getList()}
                </div>
            </div>
        )
    }

    return (
        <div className="c-Deals">
            {_view.get()}
        </div>
    )
}

export { DealsComponent }