import { DealsComponent } from '../../components/Deals'
import { LayoutPage } from '../../components/LayoutPage'

import './index.css'

const MainPage = () => {
    return (
        <LayoutPage config={{ id: 'Main' }}>
            <div className="disclaimer">This is an affiliate website. By clicking on our product links, we may receive a small commission if you decide to purchase the item. These commissions are what make it possible for us to bring you great deals!</div>

            <div className="links">
                <a href="https://t.me/foryourconvenience1" className="links-link" target="_blank">
                    <div className="link-icon telegram"></div>

                    <div className="link-description">
                        Join the <strong>Telegram Channel</strong> for <span className="no-wrap">more deals <i className="bi bi-chevron-right"></i></span>
                    </div>
                </a>

                <a href="https://www.facebook.com/groups/fycdeals/" className="links-link" target="_blank">
                    <div className="link-icon facebook"></div>

                    <div className="link-description">
                        Join the <strong>Facebook Group</strong> for <span className="no-wrap">more deals <i className="bi bi-chevron-right"></i></span>
                    </div>
                </a>

                <a href="https://walmrt.us/3vvOoNP" className="links-link" target="_blank">
                    <div className="link-description">
                        <strong>Walmart+ Membership</strong> <span className="no-wrap">Free 30-Day Trial <i className="bi bi-chevron-right"></i></span>
                    </div>
                </a>
            </div>

            <DealsComponent />

            <div className="links">
                <a href="https://amzn.to/3WXFT7P" className="links-link" target="_blank">
                    <div className="link-description">
                        <strong>Amazon Prime Membership</strong> <span className="no-wrap">30 Day Trial & Discounted Memberships <i className="bi bi-chevron-right"></i></span>
                    </div>
                </a>

                <a href="https://amzn.to/3EYWDDi" className="links-link" target="_blank">
                    <div className="link-description">
                        <strong>Amazon Fresh Online Promotion</strong> <span className="no-wrap">Spend $75+, get $35 off (FRESHSAVE35)<i className="bi bi-chevron-right"></i></span>
                    </div>
                </a>
            </div>
        </LayoutPage>
    )
}

export { MainPage }