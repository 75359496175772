import { useEffect } from 'react'

import PropTypes from 'prop-types'

import { createPortal } from 'react-dom'

import './index.css'

const ModalComponent = ({ title, controls, children, config={}, events={} }) => {
    useEffect(() => {
        document.body.classList.add('no-scroll')

        return () => {
            document.body.classList.remove('no-scroll')
        }
    }, [config.show])

    return (
        config.show ? (
            createPortal((
                <div className={`c-Modal-Container ${config.classNames || ''}`}>
                    <div className="c-Modal">                       
                        {children}

                        {config.controls ? (
                            config.controls
                        ) : null}
                    </div>
                </div>
            ), document.getElementById('portals'))
        ) : null
    )
}

ModalComponent.propTypes = {
    title: PropTypes.any,

    children: PropTypes.any,

    controls: PropTypes.any,

    config: PropTypes.shape({
        showHeader: PropTypes.bool,
    })
}

export { ModalComponent }